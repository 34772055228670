/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTTP_INTERCEPTORS, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Aisle, Ingredient, ItemDto, Recipe, RecipeDto, Store, Unit } from '../dtos';
import { isFirstTimeUser } from './is-first-time-user';

@Injectable()
export class FirstTimeUsersInterceptor implements HttpInterceptor {
  constructor(
    private transloco: TranslocoService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    return this.handleRequest(request, next);
  }

  private handleRequest(request: HttpRequest<any>, next: HttpHandler): any {
    const { url, method } = request;

    if (isFirstTimeUser() && (url.startsWith(environment.apiUrl) || url.startsWith(environment.mediaUrl))) {
      if (url.endsWith('stores') && method === 'GET') {
        return of(new HttpResponse({ status: 200, body: this.stores }));
      }
      if (url.endsWith('aisles') && method === 'GET') {
        return of(new HttpResponse({ status: 200, body: this.aisles }));
      }
      if (url.endsWith('items') && method === 'GET') {
        return of(new HttpResponse({ status: 200, body: this.items }));
      }
      if (url.endsWith('ingredients') && method === 'GET') {
        return of(new HttpResponse({ status: 200, body: this.ingredients }));
      }
      if (url.endsWith('recipes') && method === 'GET') {
        return of(new HttpResponse({ status: 200, body: this.recipes }));
      }
      if (url.endsWith('descriptions/1.txt') && method === 'GET') {
        return of(new HttpResponse({ status: 200, body: this.recipes[0].recipeDescription }));
      }

      if (method === 'POST') {
        request.body['id'] = self.crypto.randomUUID();

        if (url.includes('recipes') && request.body instanceof FormData) {
          const data = request.body as FormData;
          const recipe: Recipe = {
            sk: self.crypto.randomUUID(),
            name: data.get('itemName')?.toString() ?? '',
            description: data.get('description')?.toString() ?? '',
            picture: data.get('picture')?.toString() ?? '',
            selectedCount: 0,
            ingredients: [] // todo
          };
          return of(new HttpResponse({ status: 200, body: recipe }));
        }
      }

      if (method === 'PUT') {
        if (url.includes('recipes') && request.body instanceof FormData) {
          const data = request.body as FormData;
          const recipe: RecipeDto = {
            sk: data.get('sk')?.toString() ?? '',
            recipeName: data.get('recipeName')?.toString() ?? '',
            recipeDescription: data.get('description')?.toString() ?? this.transloco.translate('demo.recipes_pouletfume_desc'),
            recipePictureUrl: data.get('picture')?.toString() ?? 'https://images.grocerylistgenerator.com/recipes/sample_recipe_image.jpg',
            recipeSelectedCount: Number(data.get('recipeSelectedCount') ?? 0),
            recipeIngredients: JSON.parse(data.get('recipeIngredients')?.toString() ?? '[]'),
            recipeTags: JSON.parse(data.get('recipeTags')?.toString() ?? '[]')
          };
          return of(new HttpResponse({ status: 200, body: recipe }));
        }
      }

      return of(new HttpResponse({ status: 200, body: request.body }));
    }

    return next.handle(request);
  }

  private get stores(): Store[] {
    return [
      {
        pk: 'dummy',
        sk: '1',
        storeName: this.transloco.translate('demo.stores_supermarket'),
        storeOrdinalPosition: 0,
        storeAisles: this.aisles
      },
    ];
  }

  private get aisles(): Aisle[] {
    return [
      {
        aisleId: '1',
        aisleName: this.transloco.translate('demo.aisles_fruitvegetables'),
        aisleOrdinalPosition: 2,
      },
      {
        aisleId: '2',
        aisleName: this.transloco.translate('demo.aisles_milkanddairy'),
        aisleOrdinalPosition: 3,
      },
      {
        aisleId: '3',
        aisleName: this.transloco.translate('demo.aisles_breadandbaked'),
        aisleOrdinalPosition: 4,
      },
      {
        aisleId: '4',
        aisleName: this.transloco.translate('demo.aisles_frozengoods'),
        aisleOrdinalPosition: 5,
      },
      {
        aisleId: '5',
        aisleName: this.transloco.translate('demo.aisles_meatanddeli'),
        aisleOrdinalPosition: 6,
      },
      {
        aisleId: '6',
        aisleName: this.transloco.translate('demo.aisles_cannedgoods'),
        aisleOrdinalPosition: 7,
      },
      {
        aisleId: '7',
        aisleName: this.transloco.translate('demo.aisles_spices'),
        aisleOrdinalPosition: 8,
      },
      {
        aisleId: '8',
        aisleName: this.transloco.translate('demo.aisles_drinks'),
        aisleOrdinalPosition: 9,
      },
      {
        aisleId: '9',
        aisleName: this.transloco.translate('demo.aisles_snacks'),
        aisleOrdinalPosition: 10,
      },
      {
        aisleId: '10',

        aisleName: this.transloco.translate('demo.aisles_cleaningproducts'),
        aisleOrdinalPosition: 11,
      },
      {
        aisleId: '11',
        aisleName: this.transloco.translate('demo.aisles_healthandbeauty'),
        aisleOrdinalPosition: 12,
      },
    ];
  }

  private get recipes(): RecipeDto[] {
    return [
      {
        sk: '1',
        recipeName: this.transloco.translate('demo.recipes_pouletfume_name'),
        recipeDescription: this.transloco.translate('demo.recipes_pouletfume_desc'),
        recipeSelectedCount: 0,
        recipePictureUrl: 'https://images.grocerylistgenerator.com/recipes/sample_recipe_image.jpg',
        recipeIngredients: this.ingredients
      },
    ];
  }

  private get ingredients(): Ingredient[] {
    return [
      {
        itemId: '56',
        recipeAmount: 1,
        recipeUnit: Unit.Item,
      },
      {
        itemId: '81',
        recipeAmount: 1,
        recipeUnit: Unit.Item,
      },
      {
        itemId: '18',
        recipeAmount: 2,
        recipeUnit: Unit.Item,
      },
      {
        itemId: '123',
        recipeAmount: 1,
        recipeUnit: Unit.Item,
      },
      {
        itemId: '124',
        recipeAmount: 200,
        recipeUnit: Unit.Gram,
      },
      {
        itemId: '92',
        recipeAmount: 200,
        recipeUnit: Unit.Gram,
      },
      {
        itemId: '44',
        recipeAmount: 1,
        recipeUnit: Unit.Item,
      },
      {
        itemId: '125',
        recipeAmount: 1,
        recipeUnit: Unit.Item,
      },
    ];
  }

  private get items(): ItemDto[] {
    return [
      {
        itemIsInShoppingCart: false,
        pk: 'dummy',
        sk: '1',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_apple'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '2',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_banana'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '3',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_potatoes'),
        itemShoppingUnit: Unit.Kilo,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '4',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_cauliflower'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '5',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_broccoli'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '6',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_strawberries'),
        itemShoppingUnit: Unit.Pound,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '7',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_pear'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '8',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_kiwi'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '9',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_onion'),
        itemShoppingUnit: Unit.Pound,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '10',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_melon'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '11',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_tomato'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '12',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_artichoke'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '13',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_kale'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '14',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_spinach'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '15',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_parsley'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '16',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_lettuce'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '18',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_leek'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '19',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_endive'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '20',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_brusselssprouts'),
        itemShoppingUnit: Unit.Pound,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '21',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_kohlrabi'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '22',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_celery'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '23',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_lemongrass'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '24',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_asparagus'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '25',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_bambooshoots'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '26',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_ginger'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '27',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_sweetpotatoes'),
        itemShoppingUnit: Unit.Kilo,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '28',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_yams'),
        itemShoppingUnit: Unit.Kilo,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '29',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_soybean'),
        itemShoppingUnit: Unit.Gram,
        itemShoppingAmount: 500,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '30',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_peas'),
        itemShoppingUnit: Unit.Gram,
        itemShoppingAmount: 500,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '31',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_carrot'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '32',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_parsnips'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '33',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_beans'),
        itemShoppingUnit: Unit.Gram,
        itemShoppingAmount: 500,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '34',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_radishes'),
        itemShoppingUnit: Unit.Gram,
        itemShoppingAmount: 500,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '35',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_turnips'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '36',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_shallots'),
        itemShoppingUnit: Unit.Pound,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '37',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_garlic'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '38',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_cucumber'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '39',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_squash'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '40',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_pumpkin'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '41',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_bellpeppergreen'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '42',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_bellpepperred'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '43',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_bellpepperyellow'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '44',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_chillipepper'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '45',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_eggplant'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsStockItem: false,
        itemIsFavourite: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '46',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_springonion'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '47',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_avocado'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '48',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_lentils'),
        itemShoppingUnit: Unit.Gram,
        itemShoppingAmount: 500,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '49',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_cranberries'),
        itemShoppingUnit: Unit.Pound,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '50',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_peach'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '51',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_cherry'),
        itemShoppingUnit: Unit.Pound,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '52',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_olives'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '53',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_pineapple'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '54',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_orange'),
        itemShoppingUnit: Unit.Pound,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '55',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_mandarin'),
        itemShoppingUnit: Unit.Pound,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '56',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_lemon'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '57',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_lime'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '58',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_grapefruit'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '59',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_salmon'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '60',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_steak'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '61',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_steaktbone'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '62',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_steakribeye'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '63',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_steaktartare'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '64',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_steaksirloin'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '65',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_steakfiletmignon'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '66',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_chickenbreast'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '67',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_chickenfilet'),
        itemShoppingUnit: Unit.Gram,
        itemShoppingAmount: 500,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '68',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_chickenwings'),
        itemShoppingUnit: Unit.Pound,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '69',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_lambrack'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '70',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_ham'),
        itemShoppingUnit: Unit.Gram,
        itemShoppingAmount: 100,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '71',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_bacon'),
        itemShoppingUnit: Unit.Gram,
        itemShoppingAmount: 100,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '72',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_roulade'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '73',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_porkchop'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '74',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_baconcubes'),
        itemShoppingUnit: Unit.Gram,
        itemShoppingAmount: 250,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '75',
        itemIsIngredient: true,
        itemAisleId: '7',
        itemName: this.transloco.translate('demo.item_basil'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '76',
        itemIsIngredient: true,
        itemAisleId: '7',
        itemName: this.transloco.translate('demo.item_mint'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '77',
        itemIsIngredient: true,
        itemAisleId: '7',
        itemName: this.transloco.translate('demo.item_mustard'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '78',
        itemIsIngredient: true,
        itemAisleId: '7',
        itemName: this.transloco.translate('demo.item_pepperblack'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '79',
        itemIsIngredient: true,
        itemAisleId: '7',
        itemName: this.transloco.translate('demo.item_pepperwhite'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '80',
        itemIsIngredient: true,
        itemAisleId: '7',
        itemName: this.transloco.translate('demo.item_pepperchilli'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '81',
        itemIsIngredient: true,
        itemAisleId: '7',
        itemName: this.transloco.translate('demo.item_coriander'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '82',
        itemIsIngredient: true,
        itemAisleId: '7',
        itemName: this.transloco.translate('demo.item_salt'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '83',
        itemIsIngredient: true,
        itemAisleId: '7',
        itemName: this.transloco.translate('demo.item_cinnamon'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '84',
        itemIsIngredient: true,
        itemAisleId: '7',
        itemName: this.transloco.translate('demo.item_dill'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '85',
        itemIsIngredient: true,
        itemAisleId: '7',
        itemName: this.transloco.translate('demo.item_oregano'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '86',
        itemIsIngredient: true,
        itemAisleId: '7',
        itemName: this.transloco.translate('demo.item_sage'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '87',
        itemIsIngredient: true,
        itemAisleId: '7',
        itemName: this.transloco.translate('demo.item_tarragon'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '88',
        itemIsIngredient: true,
        itemAisleId: '7',
        itemName: this.transloco.translate('demo.item_thyme'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '89',
        itemIsIngredient: true,
        itemAisleId: '7',
        itemName: this.transloco.translate('demo.item_vanilla'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '90',
        itemIsIngredient: false,
        itemAisleId: '6',
        itemName: this.transloco.translate('demo.item_tea'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '91',
        itemIsIngredient: false,
        itemAisleId: '6',
        itemName: this.transloco.translate('demo.item_coffee'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '92',
        itemIsIngredient: true,
        itemAisleId: '6',
        itemName: this.transloco.translate('demo.item_rice'),
        itemShoppingUnit: Unit.Gram,
        itemShoppingAmount: 500,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '93',
        itemIsIngredient: true,
        itemAisleId: '6',
        itemName: this.transloco.translate('demo.item_oliveoil'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '94',
        itemIsIngredient: false,
        itemAisleId: '3',
        itemName: this.transloco.translate('demo.item_breadwhite'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '95',
        itemIsIngredient: false,
        itemAisleId: '3',
        itemName: this.transloco.translate('demo.item_breadbrown'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '96',
        itemIsIngredient: false,
        itemAisleId: '3',
        itemName: this.transloco.translate('demo.item_breadrolls'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '97',
        itemIsIngredient: false,
        itemAisleId: '3',
        itemName: this.transloco.translate('demo.item_cereals'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '98',
        itemIsIngredient: false,
        itemAisleId: '8',
        itemName: this.transloco.translate('demo.item_juice'),
        itemShoppingUnit: Unit.Liter,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '99',
        itemIsIngredient: true,
        itemAisleId: '8',
        itemName: this.transloco.translate('demo.item_beer'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '100',
        itemIsIngredient: true,
        itemAisleId: '8',
        itemName: this.transloco.translate('demo.item_winewhite'),
        itemShoppingUnit: Unit.Liter,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '101',
        itemIsIngredient: true,
        itemAisleId: '8',
        itemName: this.transloco.translate('demo.item_winered'),
        itemShoppingUnit: Unit.Liter,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '102',
        itemIsIngredient: false,
        itemAisleId: '8',
        itemName: this.transloco.translate('demo.item_water'),
        itemShoppingUnit: Unit.Liter,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '103',
        itemIsIngredient: false,
        itemAisleId: '8',
        itemName: this.transloco.translate('demo.item_softdrink'),
        itemShoppingUnit: Unit.Liter,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '104',
        itemIsIngredient: true,
        itemAisleId: '2',
        itemName: this.transloco.translate('demo.item_eggs'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 6,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '105',
        itemIsIngredient: true,
        itemAisleId: '2',
        itemName: this.transloco.translate('demo.item_butter'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '106',
        itemIsIngredient: true,
        itemAisleId: '2',
        itemName: this.transloco.translate('demo.item_margarine'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '107',
        itemIsIngredient: true,
        itemAisleId: '2',
        itemName: this.transloco.translate('demo.item_yogurt'),
        itemShoppingUnit: Unit.Liter,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '108',
        itemIsIngredient: false,
        itemAisleId: '9',
        itemName: this.transloco.translate('demo.item_chips'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '109',
        itemIsIngredient: true,
        itemAisleId: '9',
        itemName: this.transloco.translate('demo.item_chocolate'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '110',
        itemIsIngredient: false,
        itemAisleId: '9',
        itemName: this.transloco.translate('demo.item_liquorice'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '111',
        itemIsIngredient: false,
        itemAisleId: '9',
        itemName: this.transloco.translate('demo.item_cookies'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '112',
        itemIsIngredient: false,
        itemAisleId: '4',
        itemName: this.transloco.translate('demo.item_icecream'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '113',
        itemIsIngredient: false,
        itemAisleId: '4',
        itemName: this.transloco.translate('demo.item_pizza'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '114',
        itemIsIngredient: false,
        itemAisleId: '10',
        itemName: this.transloco.translate('demo.item_toiletpaper'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '115',
        itemIsIngredient: false,
        itemAisleId: '10',
        itemName: this.transloco.translate('demo.item_laundrydetergent'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '116',
        itemIsIngredient: false,
        itemAisleId: '10',
        itemName: this.transloco.translate('demo.item_dishwashingsoap'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '117',
        itemIsIngredient: false,
        itemAisleId: '10',
        itemName: this.transloco.translate('demo.item_dishcloth'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '118',
        itemIsIngredient: false,
        itemAisleId: '11',
        itemName: this.transloco.translate('demo.item_toothpaste'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '119',
        itemIsIngredient: false,
        itemAisleId: '11',
        itemName: this.transloco.translate('demo.item_toothbrush'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '120',
        itemIsIngredient: false,
        itemAisleId: '11',
        itemName: this.transloco.translate('demo.item_deodorant'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '121',
        itemIsIngredient: false,
        itemAisleId: '11',
        itemName: this.transloco.translate('demo.item_shampoo'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '122',
        itemIsIngredient: false,
        itemAisleId: '11',
        itemName: this.transloco.translate('demo.item_shavingcream'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '123',
        itemIsIngredient: true,
        itemAisleId: '1',
        itemName: this.transloco.translate('demo.item_napacabbage'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '124',
        itemIsIngredient: true,
        itemAisleId: '5',
        itemName: this.transloco.translate('demo.item_chickenfiletsmoked'),
        itemShoppingUnit: Unit.Gram,
        itemShoppingAmount: 200,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: false,
      },
      {
        itemIsInShoppingCart: false, pk: 'dummy',
        sk: '125',
        itemIsIngredient: true,
        itemAisleId: '6',
        itemName: this.transloco.translate('demo.item_soysauce'),
        itemShoppingUnit: Unit.Item,
        itemShoppingAmount: 1,
        itemShoppingListAmount: 0,
        itemIsFavourite: false,
        itemIsStockItem: true,
      },
    ];
  }
}

export const firstTimeUsersProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: FirstTimeUsersInterceptor,
  multi: true,
};
